import React from 'react';

const HEIGHT = 40;
const WIDTH = 130;
const LINE_WIDTH = 4;

class DonoLink extends React.Component {

  constructor(props) {
    super(props);

    this.canvasEl = React.createRef();
    this.overlayEl = React.createRef();
    this.wrapperEl = React.createRef();
  }

  componentDidMount() {
    this.draw();
  }

  draw = () => {
    // const WIDTH = this.wrapperEl.current.getBoundingClientRect().width;
    const canvas = this.canvasEl.current;
    const overlay = this.overlayEl.current;
    const ctx = canvas.getContext('2d');

    const width = WIDTH * window.devicePixelRatio;
    const height = HEIGHT * window.devicePixelRatio;

    canvas.style.width = `${WIDTH}px`;
    canvas.style.height = `${HEIGHT}px`;
    overlay.style.width = `${WIDTH}px`;
    overlay.style.height = `${HEIGHT}px`;
    canvas.width = width;
    canvas.height = height;

    // Draw the ellipse
    ctx.beginPath();
    ctx.strokeStyle = `white`;
    ctx.fillStyle = `rgba(0,0,0,0})`;
    ctx.lineWidth = LINE_WIDTH;
    ctx.ellipse(
      width / 2,
      height / 2,
      (width / 2) - LINE_WIDTH,
      (height / 2) - LINE_WIDTH,
      0,
      0,
      2 * Math.PI
    );
    ctx.fill();
    ctx.stroke();
  }

  render() {
    return (
      <>
        <div
          className="oval-button-wrapper"
          ref={this.wrapperEl}
        >
          <canvas
            ref={this.canvasEl}
            className="oval-button-canvas"
          />
          <div
            className="oval-button-content"
            ref={this.overlayEl}
          >
            <a
              className="dono-button link-button"
              href="https://www.paypal.com/paypalme/siebensechsk"
              target="_blank"
            >Donate</a>
          </div>

        </div>
      </>
    )
  }
}

export default DonoLink;
