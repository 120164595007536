import React from 'react'
import {navigate} from "@reach/router"
import Layout from "../components/Layout";
import {kebabCase} from "lodash";
import {graphql, StaticQuery, Link} from "gatsby";
import Tag from "../components/Tag";
import {HTMLContent} from '../components/Content'
import DonoLink from "../components/DonoLink";


class LandingPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tag: null
    }
  }

  handleTagSelect = (tag) => {
    // For mobile, just open link
    if (window.innerWidth <= 600) {
      navigate(`/tags/${kebabCase(tag)}`)
        .catch(e => {
          console.error(e)
          console.log('Could not navigate to tag')
        })
    }

    if (tag === this.state.tag) {
      this.setState({tag: ''})
    } else {
      this.setState({tag})
    }
  }

  listTags = () => {
    const tags = this.props.data.allMarkdownRemark.group.filter(t => t.fieldValue)
    const items = []

    tags.forEach((tag, idx) => {
      items.push(
        <Tag
          index={idx}
          name={tag.fieldValue}
          key={tag.fieldValue}
          onClick={this.handleTagSelect}
          selected={tag.fieldValue === this.state.tag}
          separator={idx !== tags.length - 1}
        />
      )

    })
    return items
  }

  render() {
    const {tag} = this.state;
    const pageData = this.props.data.markdownRemark
    return (
      <Layout noDummy>
        <div className="landing-wrapper">
          <div className="landing-layout">
            <div className="intro-text">
              {pageData.frontmatter.introText}
            </div>
            <div className="tag-selection">
              {
                this.listTags()
              }
            </div>
            {pageData.frontmatter.showFeature &&
            <div className="feature-preview">
              <HTMLContent content={pageData.html}/>
            </div>
            }

            <div className="legal-link">
              <Link to="/legal">Legal / Imprint / Credits</Link>
            </div>

            <div className="social-links">
              <a
                target="_blank"
                href="https://soundcloud.com/76666"
                title="Soundcloud"
                className="link-button"
              >
                ☁
              </a>
              <a
                href="https://76666.bandcamp.com/"
                target="_blank"
                title="Bandcamp"
                className="link-button"
              >
                🎧
              </a>
              <a
                href="https://www.instagram.com/76666k_/"
                target="_blank"
                title="Instagram"
                className="link-button"
              >
                📷
              </a>
              <a
                target="_blank"
                href="https://www.facebook.com/76666k"
                title="Facebook"
                className="link-button"
              >
                🗣
              </a>
              <a
                href="mailto:76666world@gmail.com"
                className="link-button last-el"
                title="Email"
              >
                ✉
              </a>
              <div className="dono-link">

                <DonoLink/>
              </div>
            </div>



            <div className="nav-dummy"/>
          </div>

          <div className="landing-pane">
            {pageData.frontmatter.showFeature && !tag &&
            <div className="feature-preview">
              <HTMLContent content={pageData.html}/>
              <div className="nav-dummy"/>
            </div>
            }
            {tag && <iframe
              className="tag-preview"
              src={`/tags/${kebabCase(tag)}`}
              frameBorder="0"
            />}
          </div>

        </div>
      </Layout>

    );
  }
}

export default () => (
  <StaticQuery
    query={graphql`
  query TagsQuery {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(limit: 1000) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
    markdownRemark(frontmatter: {category: {eq: "landing"}}) {
    html
    frontmatter {
      showFeature
      introText
    }
  }
  }
`}
    render={(data) => <LandingPage data={data}/>}
  />
)


